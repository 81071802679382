import React, { useState, useReducer } from 'react';
import Container from '../components/container';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Layout from "../components/layout"
import SEO from "../components/seo"
import './login.scss';

function requestReducer(state, action) {
  switch (action.type) {
    case 'send':
      return {isLoading: true, status: null}
    case 'success':
      return {isLoading: false, status: 'success'}
    case 'error':
      return {isLoading: false, status: 'error'}
  }
}

const ForgotPassword = () => {
  const [emailInput, setEmailInput] = useState('');
  const [request, dispatchRequest] = useReducer(requestReducer, {
    isLoading: false,
    status: null
  });


  const handleRequest = async (event) => {
    dispatchRequest({type: 'send'});
    event.preventDefault();

    try {
      const {data} = await axios({
        method: 'POST',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/auth/forgot-password`,
        data: {
          email: emailInput
        }
      });
      dispatchRequest({type: 'success'});
      setEmailInput('');
    } catch (e) {
      dispatchRequest({type: 'error'});
    }
  }

  return (
    <Layout>
      <SEO title="Passwort vergessen"/>
      <Container>
        <h1>Passwort zurücksetzen</h1>
        <p style={{textAlign: 'center'}}>
          Bitte geben Sie die E-Mail Adresse Ihres Accounts ein. Sie erhalten anschließend eine E-Mail mit einem Link zur Zurücksetzung des Passworts.
        </p>
        <form className="form" onSubmit={handleRequest}>
          <div className="form__form-group">
            {/*<label htmlFor="email">E-Mail Adresse</label>*/}
            <input
              type="email"
              id="email"
              name="identifier"
              required={true}
              placeholder="E-Mail Adresse"
              value={emailInput}
              onChange={(event) => setEmailInput(event.target.value)}
            />
          </div>
          <div className="form__form-group">
            <button className="button button_size_large-1" type="submit">
              {request.isLoading ? (
                <FontAwesomeIcon className="" icon={faSpinner} spin/>
              ) : (
                "Bestätigen"
              )}
            </button>
          </div>
          <span className={`form__status-text ${request.status === 'error' && 'form__status-text_error'} ${request.status === 'success' && 'form__status-text_success'}`}>
            {request.status === 'error' && "Bestätigung fehlgeschlagen"}
            {request.status === 'success' && "Erfolgreich bestätigt. Bitte überprüfen Sie Ihr E-Mail Postfach."}
          </span>
        </form>
      </Container>
    </Layout>
  );
};

export default ForgotPassword;
